// TODO: Remove everything we dont need here. We try to use basicly the bootstrap_configuration to prevent duplications.
// ATTENTION: At the bottom of this file we define customer specific variable overrides - you have to devine via the following switch variable, which customer is active.

// ***********************************************
// Load customer specific variables
// ***********************************************

// Default config
@import 'configuration/conf_default';

// TSMC: FGT
// @import "configuration/conf_tsmc_fgt";

// TSMC: Gaum
// @import "configuration/conf_tsmc_gaum";

// TSMC: Wussak
// @import "configuration/conf_tsmc_wussak";



// Bootstrap
@import 'configuration/bootstrap_configuration';
