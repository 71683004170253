// compileCompressed: ../../css/webks.basics/$1.css
// This file is only for styles that are not specific per website - global fixes, improvements & webks brand styles only.
// _base.scss imports _custom.scss and other partials.
@import "../base";

// =============================================================================
//  Branding (webks)
// =============================================================================

a[href="http://www.webks.de"]:hover{ color:$webks-blue !important; }
a[href="http://www.drowl.de"]:hover{ color:$drowl-green !important; }

// Colors
.webks-blue{ color:$webks-blue; }
.drowl-green{ color:$drowl-green; }

// webks + drowl Branding Block
.block--drowl-brand{
  text-align:center;
  padding-top:15px;
  .block-title{
    display:inline-block;
    font-size:$font-size-px;
  }
  .block-content{
    display:inline-block;
    line-height:33px;
    *{
      vertical-align:middle;
    }
  }
  &:hover{
    color:$drowl-green;
    .developer-brand__logo{
      filter: grayscale(0%);
      opacity:1;
    }
  }
}
.developer-brand__logo{
  width:120px;
  margin-left:12px;
  filter: grayscale(100%);
  opacity: .6;
  transition-property: all;
}
