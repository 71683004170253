// Holds helper mixins (extends compass functionality)

// ***********************************************
// Markup free clearing
// ***********************************************
@mixin clearfix {
  overflow: hidden;   // New formatting context in better browsers
}

@mixin clearfix-after {
  &::after{
    clear:both;
    content:" ";
    display:block;
    visibility:hidden;
  }
}

// ***********************************************
// Grid
// ***********************************************
@mixin webksgrid ($number-of-cols: 2, $type-of-col: 'normal', $mobile-desktop-thin: 'desktop', $outer-inner-grid: 'inner'){
  $neighbor-columns-grid-rest: null;
  $available-grid-per-column: null;
  $number-of-grid-spaces: $number-of-cols - 1;
  $neighbor-columns-gridwidth: null;
  $summed-grid: null;

  @if $mobile-desktop-thin == 'mobile'{
    // Mobile
    $summed-grid: $mobile-grid * $number-of-grid-spaces;
    // -- Pro Grid zur verfuegung stehende Gridbreite errechnen
    $available-grid-per-column: $summed-grid / $number-of-cols;
    // -- Fuer zu :first und :last angrenzende Spalten, Rest zum tatsaechlichen Gridbreite ermitteln
    $neighbor-columns-grid-rest: $mobile-grid - $available-grid-per-column;
  }@else if $mobile-desktop-thin == 'thin'{
    // Thin
    $summed-grid: $thin-grid * $number-of-grid-spaces;
    // -- Pro Grid zur verfuegung stehende Gridbreite errechnen
    $available-grid-per-column: $summed-grid / $number-of-cols;
    // -- Fuer zu :first und :last angrenzende Spalten, Rest zum tatsaechlichen Gridbreite ermitteln
    $neighbor-columns-grid-rest: $thin-grid - $available-grid-per-column;
  }@else{
    // Default: Desktop
    $summed-grid: $grid * $number-of-grid-spaces;
    // -- Pro Grid zur verfuegung stehende Gridbreite errechnen
    $available-grid-per-column: $summed-grid / $number-of-cols;
    // -- Fuer zu :first und :last angrenzende Spalten, Rest zum tatsaechlichen Gridbreite ermitteln
    $neighbor-columns-grid-rest: $grid - $available-grid-per-column;
  }
  // -- Breite Gridbreite fuer Normalfaelle errechnen (nicht an :first und :last angrenzend)
  $middle-columns-gridwidth: $available-grid-per-column / 2;
  $neighbor-columns-gridwidth: $available-grid-per-column - $neighbor-columns-grid-rest;

  // Return
  @if $type-of-col == 'first'{
    clear:left;
    @if $outer-inner-grid == 'outer'{
      margin-right: $available-grid-per-column;
      margin-left:0;
    }@else if $outer-inner-grid == 'inner'{
      padding-right: $available-grid-per-column;
      padding-left:0;
    }
  }@else if $type-of-col == 'next-to-first'{
    @if $outer-inner-grid == 'outer'{
      margin-left: $neighbor-columns-grid-rest;
      margin-right: $neighbor-columns-gridwidth;
    }@else if $outer-inner-grid == 'inner'{
      padding-left: $neighbor-columns-grid-rest;
      padding-right: $neighbor-columns-gridwidth;
    }
  }@else if $type-of-col == 'last'{
    @if $outer-inner-grid == 'outer'{
      margin-left: $available-grid-per-column;
      margin-right: 0;
    }@else if $outer-inner-grid == 'inner'{
      padding-left: $available-grid-per-column;
      padding-right: 0;
    }
  }@else if $type-of-col == 'next-to-last'{
    @if $outer-inner-grid == 'outer'{
      margin-left: $neighbor-columns-gridwidth;
      margin-right: $neighbor-columns-grid-rest;
    }@else if $outer-inner-grid == 'inner'{
      padding-left: $neighbor-columns-gridwidth;
      padding-right: $neighbor-columns-grid-rest;
    }
  }@else if $type-of-col == 'normal'{
    @if $outer-inner-grid == 'outer'{
      margin-left: $middle-columns-gridwidth;
      margin-right: $middle-columns-gridwidth;
    }@else if $outer-inner-grid == 'inner'{
      padding-left: $middle-columns-gridwidth;
      padding-right: $middle-columns-gridwidth;
    }
  }
}

// ***********************************************
// Menus
// ***********************************************
@mixin horizontal-menu($withcutlines: null){
  width:auto;
  @include clearfix-after;
  > li{
    display:block;
    float:left;
    @if($withcutlines != null){
      border-width:0 1px 0 0;
      border-style:solid;
      border-color:$grey-light;
      &.last{ border-right:0; }
    }
  }
}

@mixin vertical-menu($withcutlines: null){
  // Only use case for this, is overriding a horizontal menu
  // A menu without styling allready is a vertical menu!
  > li{
    display:block;
    @if($withcutlines != null){
      border-width:0 0 1px;
      &.last{ border-bottom:0; }
    }
  }
}

// ***********************************************
// Simple Linear Gradients
// ***********************************************

// Bourbon does this.

//
// element-invisible improved and more robust
//
@mixin element-invisible {
  border: 0;
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
    clip: rect(1px 1px 1px 1px); // IE6 and IE7 use the wrong syntax
  }
  clip: rect(1px, 1px, 1px, 1px);
}

//
// Turns off the element-invisible effect
//
@mixin element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

// ***********************************************
// Animation & Keyframe Mixin
// ***********************************************

// Bourbon should do this all.
