// Holds reusable objects for global use

// Mixins
// *-mod means: It´s a modifier mixin, wich for eg. only change the color of a button.

// ***********************************************
// Buttons
// ***********************************************

@mixin btn($color-scheme: 'default', $size: 'default', $icon: null, $icon-only: 'false'){
  border-width:1px;
  border-style:solid;
  display: inline-block;
  font-size: $font-size-px;
  font-weight: $font-weight-normal;
  line-height: 1.05em;
  cursor:pointer;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  @include user-select(none);

  // Color
  @include btn-mod($color-scheme);

  // Size
  @include btn-size($size);

  // Icon Only (remove text, show only the icon)
  @if($icon-only != 'false'){
    text-indent:-9000px;
    position:relative;
    padding:.55em 15px;
  }

  @if($icon-only != 'false' OR $icon != null){
    &::before{
      // Icon
      @if($icon-only != 'false'){
        text-indent:0;
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        line-height:2em;
        vertical-align:middle;
      }else{
        line-height: 1;
      }
      @if($icon != null){
        display: inline-block;
        font-family: $iconfont;
        font-style: normal;
        font-weight: normal;
        margin-right:5px;
        content: map-get($icon-list, $icon);
      }
    }
  }
  &:hover{
    text-decoration:none;
  }
}

// Button Modifier
// -- Size
@mixin btn-size($size){
  @if($size == xs){
    @include button-size($btn-xs-v-space, $btn-xs-h-space, $btn-default-font-size, $btn-default-line-height, $btn-radius);
  }@else if( $size == xl){
    @include button-size($btn-xl-v-space, $btn-xl-h-space, $btn-default-font-size, $btn-default-line-height, $btn-radius);
  }@else if( $size == xxl){
    @include button-size($btn-xxl-v-space, $btn-xxl-h-space, $btn-default-font-size, $btn-default-line-height, $btn-radius);
  }@else if( $size == xxxl){
    @include button-size($btn-xxxl-v-space, $btn-xxxl-h-space, $btn-default-font-size, $btn-default-line-height, $btn-radius);
  }@else{
    // Default
    @include button-size($btn-default-v-space, $btn-default-h-space, $btn-default-font-size, $btn-default-line-height, $btn-radius);
  }
}

// -- Style
@mixin btn-mod($color-scheme, $actions-only: 'false'){
  // Adds only the new colors, not the entire button code
  // $actions_only: For buttons they show onother color scheme on :hover & :active
  // TODO: actions_only may not the best solution, maybe we simply have to define seperate color schemes for buttons like eg. 'normal-to-red'
  @if($color-scheme == 'red'){
    @if($actions-only == 'false'){
      background-color:$danger !important;
      border-color:$danger !important;
      text-shadow:0 1px 1px lighten($danger, 12%);
      color:$white !important;
    }
    &:hover{
      background-color: lighten($danger, 3%) !important;
      border-color: lighten($danger, 3%) !important;
      color:$white !important;
    }
    &:active,
    &:focus{
      background-color:darken($danger, 3%) !important;
      border-color:darken($danger, 3%) !important;
      color:darken($danger, 20%) !important;
    }
  }@else if( $color-scheme == 'green'){
    @if($actions-only == 'false'){
      background-color:$success !important;
      border-color:$success !important;
      color:$white !important;
    }
    &:hover{
      background-color: lighten($success, 3%) !important;
      border-color: lighten($success, 3%) !important;
      color:$white !important;
    }
    &:active,
    &:focus{
      background-color: darken($success, 3%) !important;
      border-color: darken($success, 3%) !important;
      color:darken($success, 20%) !important;
    }
  }@else if( $color-scheme == 'grey'){
    @if($actions-only == 'false'){
      background-color: darken($grey-light, 3%) !important;
      border-color: darken($grey-light, 3%) !important;
      color:$text-color !important;
    }
    &:hover{
      background-color: $highlight !important;
      border-color: $highlight !important;
      color:$highlight-text-color !important;
    }
    &:active,
    &:focus{
      background-color: darken($grey-light, 3%) !important;
      border-color: darken($grey-light, 3%) !important;
      color:darken($grey, 20%) !important;
    }
  }@else if( $color-scheme == 'disabled'){
    @if($actions-only == 'false'){
      background-color: darken($grey-light, 3%) !important;
      border-color: $grey !important;
      color:darken($grey, 15%) !important;
    }
    &:hover{
      background-color: darken($grey-light, 5%) !important;
      border-color: $grey !important;
      color:darken($grey, 15%) !important;
    }
    &:active,
    &:focus{
      background-color:darken($danger, 3%) !important;
      border-color:darken($danger, 3%) !important;
      color:darken($danger, 20%) !important;
    }
  }@else if( $color-scheme == 'ghost'){
    @if($actions-only == 'false'){
      background-color:$btn-ghost-bg-color !important;
      border-color:$btn-ghost-border-color !important;
      color:$btn-ghost-text-color !important;
    }
    &:hover{
      background-color: $btn-ghost-bg-color !important;
      border-color:lighten($btn-ghost-border-color, 5%) !important;
      color:$btn-ghost-text-color !important;
    }
    &:active,
    &:focus{
      background-color:$btn-ghost-bg-color !important;
      border-color:darken($btn-ghost-border-color, 5%) !important;
      color:$btn-ghost-text-color !important;
    }
  }@else if( $color-scheme == 'highlight'){
    @if($actions-only == 'false'){
      // Active (default button active state)
      border-color:$highlight !important;
      background-color:$highlight !important;
      color: $highlight-text-color !important;
    }
    &:hover{
      border-color:lighten($highlight, 5%) !important;
      background-color:lighten($highlight, 5%) !important;
      color: $highlight-text-color !important;
    }
    &:active,
    &:focus{
      border-color:darken($highlight, 5%) !important;
      background-color:darken($highlight, 5%) !important;
      color: $highlight-text-color !important;
    }
  }@else if( $color-scheme == 'highlight-invert'){
    @if($actions-only == 'false'){
      border-color:$btn-default-border-color !important;
      background-color:$btn-default-bg-color !important;
      color: $highlight !important;
    }
    &:hover{
      border-color:lighten($btn-default-border-color, 5%) !important;
      background-color:lighten($btn-default-bg-color, 5%) !important;
      color: $highlight !important;
    }
    &:active,
    &:focus{
      border-color:darken($btn-default-border-color, 5%) !important;
      background-color:darken($btn-default-bg-color, 5%) !important;
      color: $highlight !important;
    }
  }@else if( $color-scheme == 'naked'){
    @if($actions-only == 'false'){
      // Active (default button active state)
      border-color:transparent !important;
      background-color:transparent !important;
      color: $text-color !important;
      text-decoration:underline;
      padding-left:0 !important;
      padding-right:0 !important;
    }
    &:hover{
      border-color:transparent !important;
      background-color:transparent !important;
      color: $highlight !important;
    }
    &:active,
    &:focus{
      border-color:transparent !important;
      background-color:transparent !important;
      color: $highlight !important;
    }
  }@else if( $color-scheme == 'active'){
    @if($actions-only == 'false'){
      // Active (default button active state)
      border-color:darken($grey-light, 5%) !important;
      background-color:darken($grey-light, 8%) !important;
      color: $text-color !important;
    }
    &:hover{
      background-color: $grey-light !important;
      border-color: $grey-light !important;
      color: $grey-dark !important;
    }
    &:active,
    &:focus{
      border-color:darken($grey-light, 5%) !important;
      background-color:darken($grey-light, 8%) !important;
      color: $text-color !important;
    }
  }@else{
    @if($actions-only == 'false'){
      border-color:$btn-default-border-color !important;
      background-color:$btn-default-bg-color !important;
      color: $btn-default-text-color !important;
    }
    &:hover{
      border-color:lighten($btn-default-border-color, 5%) !important;
      background-color:lighten($btn-default-bg-color, 5%) !important;
      color: $btn-default-text-color !important;
    }
    &:active,
    &:focus{
      border-color:darken($btn-default-border-color, 5%) !important;
      background-color:darken($btn-default-bg-color, 5%) !important;
      color: $btn-default-text-color !important;
    }
  }
}


// Todo: Circle item maybe should be only an formatter of .ico. Otherwise we duplicate the whole stuff we already do for .ico
@mixin circle-item($color-scheme: 'default', $size: 'default', $icon: null){
  text-indent:-9000px;
  text-align:left;
  border-radius:50%;
  padding:0 !important;
  display:inline-block;
  @if($icon != null OR $icon == 'seperate'){
    &::before{
      // Icon
      float:left;
      text-indent:0;
      vertical-align:middle;
      text-align:center;
      margin:0 !important;
      @if($icon != 'seperate'){
        content: map-get($icon-list, $icon);
      }
    }
  }

  // Color
  @include btn-mod($color-scheme);

  // Size
  @include circle-item-size($size);

}
@mixin circle-item-size($size){
  @if($size == xs){
    height:$icon-size-xs + ($btn-xs-v-space * 2);
    width:$icon-size-xs + ($btn-xs-v-space * 2);
    line-height:$icon-size-xs + ($btn-xs-v-space * 2);
    &::before{
      font-size:$icon-size-xs;
      height:$icon-size-xs + ($btn-xs-v-space * 2);
      width:$icon-size-xs + ($btn-xs-v-space * 2);
      line-height:$icon-size-xs + ($btn-xs-v-space * 2) !important;
    }
  }@else if( $size == xl){
    height:$icon-size-xl + ($btn-xl-v-space * 2);
    width:$icon-size-xl + ($btn-xl-v-space * 2);
    line-height:$icon-size-xl + ($btn-xl-v-space * 2);
    &::before{
      font-size:$icon-size-xl;
      height:$icon-size-xl + ($btn-xl-v-space * 2);
      width:$icon-size-xl + ($btn-xl-v-space * 2);
      line-height:$icon-size-xl + ($btn-xl-v-space * 2) !important;
    }
  }@else if( $size == xxl){
    height:$icon-size-xxl + ($btn-xxl-v-space * 2);
    width:$icon-size-xxl + ($btn-xxl-v-space * 2);
    line-height:$icon-size-xxl + ($btn-xxl-v-space * 2);
    &::before{
      font-size:$icon-size-xxl;
      height:$icon-size-xxl + ($btn-xxl-v-space * 2);
      width:$icon-size-xxl + ($btn-xxl-v-space * 2);
      line-height:$icon-size-xxl + ($btn-xxl-v-space * 2) !important;
    }
  }@else if( $size == xxxl){
    height:$icon-size-xxxl + ($btn-xxxl-v-space * 2);
    width:$icon-size-xxxl + ($btn-xxxl-v-space * 2);
    line-height:$icon-size-xxxl + ($btn-xxxl-v-space * 2);
    &::before{
      font-size:$icon-size-xxxl;
      height:$icon-size-xxxl + ($btn-xxxl-v-space * 2);
      width:$icon-size-xxxl + ($btn-xxxl-v-space * 2);
      line-height:$icon-size-xxxl + ($btn-xxxl-v-space * 2) !important;
    }
  }@else{
    // Default
    height:$icon-size-normal + ($btn-default-v-space * 2);
    width:$icon-size-normal + ($btn-default-v-space * 2);
    line-height:$icon-size-normal + ($btn-default-v-space * 2);
    &::before{
      font-size:$icon-size-normal;
      height:$icon-size-normal + ($btn-default-v-space * 2);
      width:$icon-size-normal + ($btn-default-v-space * 2);
      line-height:$icon-size-normal + ($btn-default-v-space * 2) !important;
    }
  }
}


// Image Buttons (completely seperate button style)
@mixin btn-image {
  text-indent:-9000px;
  display:inline-block;
  background-repeat:no-repeat;
  background-image:url('images/btn_sprite.png');
  background-color:transparent;
  border:0 none;
  outline:0 none;
  height:40px;
  width:154px; // fallback
}

// Undo Buttons
@mixin undo-button {
  font-size:$font-size;
  color:$text-color;
  background:none;
  border:0 none;
  padding:0;
  display:inline;
  &:hover{
    text-decoration:underline;
  }
}

// ***********************************************
// Icons
// ***********************************************

$icon-list: (
  search: "\eb36", // Search
  // zoom-in: "\7a", // Zoom
  // zoom-out: "\5a", // Zoom-Out
  check:"\ed72", // Check
  circle-check:"\ee76", // Circled Check
  remove:"\ed6e", // Remove (x)
  circle-remove:"\ed66", // Circled Remove (x)
  plus:"\2b", // Plus
  circle-plus:"\2a", // Circled Plus
  minus:"\2d", // Minus
  circle-minus:"\5f", // Circled Minus
  exclamation:"\f12c", // Exclamation Mark (Ausrufezeichen)
  circle-exclamation:"\ed52", // Circled Exclamation Mark (Ausrufezeichen)
  info:"\f12b", // info
  circle-info:"\ed64", // Circled info
  menu:"\ec71", // Menu
  // process:"\4d", // Cogs (Zahnraeder)
  print:"\ea68", // Printer
  phone:"\ea1d", // Phone
  mail:"\ea30", // Letter
  form:"\e922", // Pen
  user:"\eaf7", // User
  user-group:"\eaf8", // User Group
  user-add:"\eaf9", // Add User
  cart:"\e9fe", // Shopping Cart

  arrow-top:"\edd4", // Arrow Top
  arrow-right:"\edd6", // Arrow Right
  arrow-bottom:"\edd8", // Arrow Bottom
  arrow-left:"\edda", // Arrow Left

  arrow-thin-top:"\edba", // Arrow thin Top
  arrow-thin-right:"\edbe", // Arrow thin Right
  arrow-thin-bottom:"\edc2", // Arrow thin Bottom
  arrow-thin-left:"\edc6", // Arrow thin Left

  circle-arrow-top:"\ee2b", // Circled Arrow Top
  circle-arrow-right:"\ee2c", // Circled Arrow Right
  circle-arrow-bottom:"\ee2d", // Circled Arrow Bottom
  circle-arrow-left:"\ee2e", // Circled Arrow Left

  double-arrow-top:"\59", // Double Arrow Top
  double-arrow-down:"\79", // Double Arrow Down
  locked:"\eb50", // Lock (closed)
  // unlocked:"\75", // Lock (open)
  home:"\e901", // Home

  twitter:"\eef5",
  facebook:"\eeef",
  gplus:"\eeea",
  youtube:"\eefc"
);

@mixin ico($icon-key: null, $icon-color: null, $icon-size: null){
  // Default inline icon
  &::before{
    font-family:$iconfont;
    font-weight:normal !important;
    font-style:normal !important;
    margin-right:4px;
    @if($icon-key != null){
      content: map-get($icon-list, $icon-key);
    }
    @if($icon-color != null){
      color:$icon-color;
    }
    @if($icon-size != null){
      font-size:$icon-size;
    }
  }
}
@mixin ico-replacement($icon-key: null, $icon-color: null, $icon-size: null, $icon-wrapper-width: null){
  // Replaces the elements text with an icon
  text-indent:-9000px;
  text-align:left;
  display:inline-block;
  margin-right:4px;
  @if($icon-wrapper-width != null){
    width:$icon-wrapper-width;
  }@else{
    width:16px; // Fallback width - default icon size
  }
  &::before{
    text-indent:0;
    font-family:$iconfont;
    float:right;
    text-align:center;
    width:100%;
    @if($icon-key != null){
      content: map-get($icon-list, $icon-key);
    }
    @if($icon-color != null){
      color:$icon-color;
    }
    @if($icon-size != null){
      font-size:$icon-size;
    }
  }
  @include clearfix-after;
}

@mixin get-ico($icon-key: null){
  // Gets only the icon letter (for custom implementation)
  @if($icon-key != null){
    &::before{
      font-family:$iconfont;
      content: map-get($icon-list, $icon-key);
    }
  }@else{
    @warn "Icon not found!";
  }
}

@mixin get-ico-only($icon-key: null){
  // Gets only the icon letter (for custom implementation)
  @if($icon-key != null){
    content: map-get($icon-list, $icon-key);
  }@else{
    @warn "Icon not found!";
  }
}
// =============================================================================
// Navigation
// =============================================================================

// Tiny Menu
@mixin tiny-menu($menu-direction: null){
  li{
    @if($menu-direction == "horizontal"){
      float:none;
      display:inline-block;
      margin-right:$thin-grid;
      &:last-child{
        margin-right:0;
      }
    }
    a{
      padding:2px 0;
    }
  }
}

// =============================================================================
// Box Styles
// =============================================================================

@mixin box-style($color-scheme: default){
  border-width:1px;
  border-style:solid;
  padding:$grid-vertical $grid;
  // Color
  @include box-style-mod($color-scheme);
  @include clearfix;
}

// Box Style Modifier
@mixin box-style-mod($color-scheme){
  // Adds only the new colors, not the entire box code
  @if($color-scheme == info){
    position:relative;
    font-size:$font-size-sm;
    padding-left:60px;
    line-height:1.1em;
    border-color:darken($info, 8%);
    text-shadow:1px 1px 1px darken($info, 8%);
    background-color:$info;
    color:$white;
    cursor:help;
    @include get-ico('info');
    &::before{
      text-shadow:none;
      font-family:$iconfont;
      color:darken($info, 8%);
      background-color:$white;
      height:30px;
      width:30px;
      border-radius:50%;
      text-align:center;
      line-height:30px;
      vertical-align:middle;
      position:absolute;
      left:15px;
      top:13px;
      font-size:22px;
    }
  }@else{
    // Default
    border-color:$grey-light;
    border-radius:$border-radius;
    background-color:$white;
  }
}


// ***********************************************
// Badges
// ***********************************************
@mixin badge($color-scheme: 'default', $icon: null){
  display: inline-block;
  font-size: $font-small;
  line-height: 1em;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border-radius:$border-radius-small;
  padding:3px 5px;

  // Icons
  &::before{
    display:inline-block;
    margin-right:3px;
  }

  // Color
  @if($color-scheme != null){
    @include badge-mod($color-scheme);
  }
}

// Button Modifier
@mixin badge-mod($color-scheme){
  @if($color-scheme == 'red'){
    background-color:$danger !important;
    color:$white !important;
  }@else if( $color-scheme == 'green'){
    background-color:$success !important;
    color:$white !important;
  }@else if( $color-scheme == 'grey'){
    background-color: $grey-light !important;
    color:$grey !important;
  }@else{
    background-color:$highlight !important;
    color: $highlight-text-color !important;
  }
}


// ***********************************************
// Misc
// ***********************************************

// Flyout
@mixin flyout($padding: $mobile-grid, $direction: right){
  background:$white;
  color:$text-color;
  padding:$padding;
  box-shadow:$shadow;
  min-width:235px;
  z-index:999;

  position:absolute;
  top:100%;
  @if($direction == left){
    right:0;
  }@else{
    left:0;
  }
}

// Overlay
@mixin overlay{
  display:none;
  position:absolute;
  top:0;
  left:0;
  z-index:9998;
  height:100%;
  width:100%; // overriden by jquery
  background:$white-glass;
}

// Cutline
@mixin cutline($direction: bottom, $spacing-one: $grid-vertical, $spacing-two: $grid-vertical){
  @if($direction == top){
    border-top:1px solid $grey-light;
    padding-top:$spacing-one;
    margin-top:$spacing-two;
  }@else if($direction == left){
    border-right:1px solid $grey-light;
    padding-right:$spacing-one;
    margin-right:$spacing-two;
  }@else if($direction == right){
    border-left:1px solid $grey-light;
    padding-left:$spacing-one;
    margin-left:$spacing-two;
  }@else{
    border-bottom:1px solid $grey-light;
    padding-bottom:$spacing-one;
    margin-bottom:$spacing-two;
  }
}

// Mobile image
@mixin mobile-image{
  text-align:center;
  display:block;
  margin:0 auto $grid-vertical;
}
