//
// 'base' partial
//
// To make it easier to use all variables and mixins in any SCSS file in this
// theme, each .scss file has a @import 'base' declaration. And this base.scss
// file is in charge of importing all the other partials needed for the theme.
//

//
// Partials to be shared with all .scss files.
//

// @import 'compass/support';                     // Add Compass' IE and vendor prefix support variables.
// @import 'compass/utilities/general/clearfix';  // Better than Drupal's clearfix.
// @import 'compass/utilities/sprites';           // See http://compass-style.org/help/tutorials/spriting/
// @import 'compass/css3';                        // Use one CSS3 mixin instead of multiple vendor prefixes.


//
// Import custom partial - mixins and variables defined for your theme


// TODO: Check if libs like bourbon are still necessary with autoprefixer.
// @import "../../+technik/libs/bourbon/core/bourbon"; // bourbon 5 skipped a lot of browser vendor prefix mixins. Should be only used with https://github.com/postcss/autoprefixer
// @import "../../+technik/libs/bourbon-4/app/assets/stylesheets/bourbon"; // At least we removed this thing. I dont see any usecase while using autoprefixer.
@import 'configuration';
@import 'libs/bootstrap/mixins';
@import 'helper';
@import 'objects';
