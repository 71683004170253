// ***********************************************
// Settings
// ***********************************************

// Dev mode?
$dev-mode: true;
// Onepager
$onepage-layout: false;
// Touch Menu Opener Label anzeigen "Menu"
$show-touch-menu-label: false; // TODO: Obsolete?
// Footer Menu Vertikal oder Horizontal darstellen
$show-footer-menu-vertical: false;
// Navigation Bar has a search-block inside it? (Headroom.js)
$navbar-searchblock: true;
// Tile Grid
$layout-tile-grid-active: false;

// Images Folder (relative to CSS folder)
$images: "../images"; // example: background: url(#{$image}/image.jpg)

// ***********************************************
// Color Variables
// Colors used in the sample global.styles. You can use these to recolor many
// elements such as forms, tables, tabs etc.
// ***********************************************

// webks
$webks-grey: #454545;
$webks-grey-light: #e2e2e2;
$webks-blue: #2e90d0;
$drowl-green: #82b22c;

// Colors
$white: #fff;
$black: #000;

$highlight:         $webks-blue;    // Primary Company CD color
$highlight-light:   lighten($highlight, 8%);
$highlight-lighter: lighten($highlight, 20%);
$highlight-dark:    darken($highlight, 8%);    // Primary Company CD color
$highlight-darker:  darken($highlight, 20%);

$highlight-sec:         $drowl-green;    // Secondary Company CD color
$highlight-sec-light:   lighten($highlight-sec, 5%);
$highlight-sec-lighter: lighten($highlight-sec, 20%);
$highlight-sec-dark:    darken($highlight-sec, 5%);
$highlight-sec-darker:  darken($highlight-sec, 20%);

// Transparent Backgrounds
$black-glass: rgba(0, 0, 0, .85);
$white-glass: rgba(255, 255, 255, .85);
$highlight-glass: rgba($highlight, .65);

// Background Colors
$highlight-bg-color: $highlight;

// Basic Greytone
$grey: #d6dce1;
$grey-light: #f0f2f4;
$grey-lighter: #f2f4f6;
$grey-dark: #414141;
$grey-darker: #333;

// Debug Colors
$debug:         rgb(255, 0, 0);
$debug-light:   lighten($debug, 5%);
$debug-dark:    darken($debug, 5%);

// Info Colors
$info:    #f9dc5c;

// Success Colors
$success: #6b9c00;
$success-light: #8fcf00;
$success-dark: darken($success, 5%);

// Warning Colors
$warning: #f0ad4e;
$warning-light: lighten($warning, 15%);
$warning-dark: darken($warning, 5%);

// Danger Colors
$danger: #d9331a;
$danger-light: lighten($danger, 15%);
$danger-dark: darken($danger, 5%);

// Text Colors
$text-color:     #505050;
$highlight-text-color: #fff; // Text color on highlight-bg
$dark-text-color: #ccc;

$page-title-color: $highlight;

// (Text) Selection Color
$selection-color: $highlight;
$selection-color-foreground: $highlight-text-color;

// Link Colors
$link-color: $text-color;
$link-color-hover: $highlight; // hover
$link-color-active: $link-color-hover; // active
$link-color-focus: $link-color-hover; // focus
$link-color-visited: $link-color; // visited

$link-style-default: none;
$link-style-hover: underline;

$touch-color: rgba(0, 0, 0, .1);


// Menus
$menu-flyout-background: $highlight;
$menu-flyout-color: $highlight-text-color;
$menu-offcanvas-background: $menu-flyout-background;
$menu-offcanvas-color: $menu-flyout-color;

// Page Element Colors
$body-bg: $highlight;
$page-background: $white;
$border-color: $grey;    // all borders set in global.styles - tables, fieldset, primary links
$image-background-color: $grey-light;

// Social Media Colors
$facebook-color: #3b5998;
$pinterest-color: #cb2027;
$gplus-color: #dd4b39;
$twitter-color: #55acee;
$youtube-color: #cd201f;

// Shadows
$shadow-intensity: rgba(0, 0, 0, .25);
$shadow: 5px 7px 1px -2px $shadow-intensity;
$shadow-small: 0 3px 3px -2px $shadow-intensity;

// Forms
$form-item-border: $grey;
$form-item-border-hover: $grey-dark;
$form-item-border-active: $highlight;

// ***********************************************
// Typography
// These font stacks are an exact copy of the stacks found in AT Cores
// theme settings for web safe fonts.
// ***********************************************

$hyphens: false;

$font-first: 'Roboto', Verdana, Geneva, 'DejaVu Sans', Arial, Helvetica, sans-serif;
$font-second: 'bebasneue', Verdana, Geneva, 'DejaVu Sans', Arial, Helvetica, sans-serif;
$font-code: Consolas, Monaco, 'Courier New', Courier, monospace, sans-serif;

$iconfont: 'iconfont';
$icon-size-xs: 12px;
$icon-size-normal: 16px;
$icon-size-xl: 32px;
$icon-size-xxl: 46px;
$icon-size-xxxl: 56px;

$font-size: 100%; // Basic font size
$font-size-px: 16px; // Basic font size pixel
$font-size-base: $font-size-px;

$font-size-sm: .85em;
$font-size-sm-px: 14px;
$font-size-md: 1.25em;
$font-size-md-px: 22px;
$font-size-lg: 1.143em;
$font-size-lg-px: 22px;
$font-weight-normal: 300;
$font-weight-bold: 500;

$line-height-base:1.428571429;
$line-height: floor(($font-size-base * $line-height-base));
$line-height-md: 1.35em;
$line-height-sm: 1em; // Line height for $font-small

$paragraph-spacing: $line-height * .6;

// Headings
$font-size-h1:   floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2:   floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3:   ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4:   ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5:   $font-size-base !default;
$font-size-h6:   ceil(($font-size-base * .85)) !default; // ~12px

$headings-font-family:    $font-first;
$headings-font-weight:    $font-weight-bold !default;
$headings-line-height:    1.1 !default;
$headings-color: #414141;
$heading1-color: $highlight;

// Cutlines
$cutline-color: $grey-light;
$cutline-height: 1px;

// ***********************************************
// Message Colors (Warnings etc.)
// ***********************************************
$message-error: $danger; // eg. red
$message-warning: $warning; // eg. yellow
$message-status: $success; // eg. green

// ***********************************************
// Metrics
// ***********************************************
$border-radius: 10px;
$border-radius-small: 5px;
$border-radius-smaller: 3px; // just for eg. images inside a thumb wrapper which has radius small

// ***********************************************
// jQuery.UI
// ***********************************************
$jq-image-folder: "images/jquery.ui/";

$jq-border: $grey;
$jq-border-radius: $border-radius-small;
$jq-background: $white;
$jq-color: $text-color;

$jq-highlight-background: $highlight;
$jq-highlight-border: $highlight;
$jq-highlight-color: $white;

$jq-hover-background: $highlight;
$jq-hover-border: $highlight;
$jq-hover-color: $white;

$jq-active-background: $highlight;
$jq-active-border: $highlight;
$jq-active-color: $white;

$jq-error-background: $warning-light;
$jq-error-border: $warning;
$jq-error-color: $warning;

$jq-header-background: $grey-light;

// ***********************************************
// Object Configuration
// ***********************************************

// Inputs
$input-default-v-space: 12px;
$input-default-h-space: 12px;
$input-default-font-size: $font-size-px;
$input-default-line-height: 1.05em;

// Buttons
$btn-radius: $border-radius-small;

// -- Defaults
$btn-default-v-space: $input-default-v-space;
$btn-default-h-space: $input-default-h-space;
$btn-default-line-height: $input-default-line-height;
$btn-default-font-size: $input-default-font-size;
$btn-default-border-color: $grey;
$btn-default-bg-color: $grey;
$btn-default-text-color: $text-color;

// -- Sizes
$btn-xs-v-space: $input-default-v-space * .75;
$btn-xs-h-space: $input-default-h-space * .75;
$btn-xl-v-space: $input-default-v-space * 1.25;
$btn-xl-h-space: $input-default-h-space * 1.25;
$btn-xxl-v-space: $input-default-v-space * 1.75;
$btn-xxl-h-space: $input-default-h-space * 1.25;
$btn-xxxl-v-space: $input-default-v-space * 2.25;
$btn-xxxl-h-space: $input-default-h-space * 2.25;

// -- Ghost
$btn-ghost-border-color: $form-item-border;
$btn-ghost-bg-color: transparent;
$btn-ghost-text-color: $text-color;


// ***********************************************
// Layout (and responsiveness)
// ***********************************************
$grid: 30px;
$half-grid: 15px;
$thin-grid: 15px;
$grid-vertical: 15px;
$mobile-grid: 20px;
$page-outer-grid:0; // This value is added to the default bootstrap outer grid (which is similar to $half-grid)
$page-outer-grid-summed: $page-outer-grid + $half-grid;
$page-max-width: 1140px;
$page-max-width-tile-pages: $page-max-width; // deprecated variable?

$sidebar-first-width: 180px + $grid;
$sidebar-second-width: 220px + $grid;

// Tile Grid & Widths
$tile-width: 357px;
$tile-grid-space:15px;
$tile-breakpoint-offset:20px;

// View Image (Image Column)
$view-image-width: 120px;

// Vertical spacings
$space-v-sm: 5px;
$space-v-md: 15px;
$space-v-lg: 35px;

// ***********************************************
// Breakpoints
// ***********************************************

$small-breakpoint-value:  768px !default;
$medium-breakpoint-value: 992px !default;
$large-breakpoint-value:  1240px !default;
$xlarge-breakpoint-value: 1680px !default;
$xxlarge-breakpoint-value: 1920px !default;

@if ($layout-tile-grid-active == true){
  // Tile layout in use
  $small-breakpoint-value:  $tile-width + $tile-breakpoint-offset  !default;
  $medium-breakpoint-value: ($tile-width * 2) + $tile-grid-space + $tile-breakpoint-offset !default;
  $large-breakpoint-value:  ($tile-width * 3) + ($tile-grid-space * 2) + $tile-breakpoint-offset !default;
  $xlarge-breakpoint-value: ($tile-width * 4) + ($tile-grid-space * 3) + $tile-breakpoint-offset !default;
  $xxlarge-breakpoint-value: ($tile-width * 5) + ($tile-grid-space * 4) + $tile-breakpoint-offset !default;
}
